import React, { Component } from "react";
import withFirebaseAuth from "react-with-firebase-auth";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import fire from "./config/firebase";

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";

import { spacing } from "@material-ui/system";

import Example from "./components/Example";
import Header from "./components/Header";

import "./App.css";

const firebaseAppAuth = fire.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider()
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  button: {
    margin: theme.spacing(3)
  },
  input: {
    display: "none"
  }
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#3c8ce7',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
     // light: '#0066ff',
      main: '#3fb41f',
      // dark: will be calculated from palette.secondary.main,
      //contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
});



class App extends Component {
  render() {
    const { user, signOut, signInWithGoogle } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
      <div>
        <Header
          user={user}
          signOut={signOut}
          signInWithGoogle={signInWithGoogle}
        />

        <Container maxWidth="sm">
          <Box p={2}>
            {user && (
              <div>
                <Example />
              </div>
            )}
          </Box>
        </Container>
      </div>
      </MuiThemeProvider>
    );
  }
}

export default withFirebaseAuth({
  providers,
  firebaseAppAuth
})(App);
