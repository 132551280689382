import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "firebase/firestore";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import People from "@material-ui/icons/PeopleTwoTone";
import Reset from "@material-ui/icons/ReplayTwoTone";
import Create from "@material-ui/icons/AddCircleTwoTone";

import fire from "../config/firebase";

const db = fire.firestore();

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));


const DEFAULT_EVENTID = "NhgEGGnUoYGqiGQyCr5L",
  DEFAULT_USERS = [
    {
      uid: "Gsaa91yYH1Y8LCZ75FlKU7qrMV23",
      challenges: {
        challenge001: {
          image: "dummies/cheers-01-large.jpg",
          likes: [
            "XEfxjMLWuJNj6uqlHlqvPGF2HPu2",
            "sWvz3uCnBnO4E4pLjbNOeLoIvVD2",
            "sWvz3uCnBnO4E4pLjbNOeLoIvVD2"
          ]
        },
        challenge002: {
          image: "dummies/entry-01-large.jpg",
          likes: [
            "XEfxjMLWuJNj6uqlHlqvPGF2HPu2",
            "sWvz3uCnBnO4E4pLjbNOeLoIvVD2"
          ]
        },
        challenge003: {
          image: "dummies/kiss-01-large.jpg",
          likes: [
            "XEfxjMLWuJNj6uqlHlqvPGF2HPu2",
            "sWvz3uCnBnO4E4pLjbNOeLoIvVD2",
            "sWvz3uCnBnO4E4pLjbNOeLoIvVD2"
          ]
        },
        challenge005: {
          image: "dummies/dance-01-large.jpg",
          likes: [
            "XEfxjMLWuJNj6uqlHlqvPGF2HPu2",
            "sWvz3uCnBnO4E4pLjbNOeLoIvVD2"
          ]
        }
      },
      username: "Michael"
    },
    {
      uid: "XEfxjMLWuJNj6uqlHlqvPGF2HPu2",
      challenges: {
        challenge001: {
          image: "dummies/cheers-02-large.jpg",
          likes: ["LAKilnRdeROF8ATbp46TUaQTqso2"]
        },
        challenge002: { image: "dummies/entry-02-large.jpg", likes: [] },
        challenge003: {
          image: "dummies/kiss-02-large.jpg",
          likes: [
            "LAKilnRdeROF8ATbp46TUaQTqso2",
            "T2IPt01ec5cp3Lw85PVLmDTeYoM2"
          ]
        },
        challenge005: { image: "dummies/dance-02-large.jpg", likes: [] }
      },
      username: "Anna"
    },
    {
      uid: "sWvz3uCnBnO4E4pLjbNOeLoIvVD2",
      challenges: {
        challenge001: {
          image: "dummies/cheers-03-large.jpg",
          likes: ["XEfxjMLWuJNj6uqlHlqvPGF2HPu2"]
        },
        challenge002: {
          image: "dummies/entry-03-large.jpg",
          likes: ["LAKilnRdeROF8ATbp46TUaQTqso2"]
        },
        challenge003: {
          image: "dummies/kiss-03-large.jpg",
          likes: [
            "LAKilnRdeROF8ATbp46TUaQTqso2",
            "T2IPt01ec5cp3Lw85PVLmDTeYoM2",
            "XEfxjMLWuJNj6uqlHlqvPGF2HPu2"
          ]
        },
        challenge005: { image: "dummies/dance-03-large.jpg", likes: [] }
      },
      username: "Max"
    },
    {
      uid: "LAKilnRdeROF8ATbp46TUaQTqso2",
      challenges: {
        challenge001: {
          image: "dummies/cheers-04-large.jpg",
          likes: ["T2IPt01ec5cp3Lw85PVLmDTeYoM2"]
        },
        challenge003: { image: "dummies/kiss-04-large.jpg", likes: [] },
        challenge005: {
          image: "dummies/dance-04-large.jpg",
          likes: [
            "T2IPt01ec5cp3Lw85PVLmDTeYoM2",
            "XEfxjMLWuJNj6uqlHlqvPGF2HPu2"
          ]
        }
      },
      username: "Sophie"
    },
    {
      uid: "T2IPt01ec5cp3Lw85PVLmDTeYoM2",
      challenges: {
        challenge001: {
          image: "dummies/cheers-05-large.jpg",
          likes: ["LAKilnRdeROF8ATbp46TUaQTqso2"]
        },
        challenge005: {
          image: "dummies/dance-05-large.jpg",
          likes: ["LAKilnRdeROF8ATbp46TUaQTqso2"]
        }
      },
      username: "Tobias"
    }
  ];

const getChallenges = () => {
  const now = new Date(),
    end01 = new Date(),
    end02 = new Date(),
    end03 = new Date(),
    end04 = new Date(),
    end05 = new Date(),
    end06 = new Date(),
    start01 = new Date(),
    start02 = new Date(),
    start03 = new Date(),
    start04 = new Date(),
    start05 = new Date(),
    start06 = new Date();

  end01.setMinutes(now.getMinutes() + 2);
  end02.setMinutes(now.getMinutes() + 10);
  end03.setHours(now.getHours() + 2);
  end04.setHours(now.getHours() + 2);
  end06.setHours(now.getHours() + 6);
  end05.setHours(now.getHours() + 6);
  start01.setHours(now.getHours() - 1);
  start02.setHours(now.getHours() - 1);
  start03.setHours(now.getHours() - 1);
  start04.setHours(now.getHours() - 1);
  start05.setHours(now.getHours() - 1);
  start06.setHours(now.getHours() + 3);
  return [
    {
      uid: "challenge001",
      title: "Mache ein Foto vom Anstoßen",
      start: start01,
      end: end01,
      photos: [],
      users: [],
      lastUpdated: now
    },
    {
      uid: "challenge002",
      title: "Ein verrücktes Selfie vor dem Eingang",
      start: start02,
      end: end02,
      photos: [],
      users: [],
      lastUpdated: now
    },
    {
      uid: "challenge003",
      title: "Ein romantischer Kuss",
      start: start03,
      end: end03,
      photos: [],
      users: [],
      lastUpdated: now
    },
    {
      uid: "challenge004",
      title: "Ein kreatives Selfie mit dem Brautpaar",
      start: start04,
      end: end04,
      photos: [],
      users: [],
      lastUpdated: now
    },
    {
      uid: "challenge005",
      title: "Foto vom verrücktesten Tänzer",
      start: start05,
      end: end05,
      photos: [],
      users: [],
      lastUpdated: now
    },
    {
      uid: "challenge006",
      title: "Lustiges Abschiedsfoto",
      start: start06,
      end: end06,
      photos: [],
      users: [],
      lastUpdated: now
    }
  ];
};

const createChallenges = () => {
  for (var challenge of getChallenges()) {
    db.collection("events")
      .doc(DEFAULT_EVENTID)
      .collection("challenges")
      .doc(challenge.uid)
      .collection("photos")
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          doc.ref.delete();
          console.log(doc.id, "=>", doc.data());
        });
      });

    db.collection("events")
      .doc(DEFAULT_EVENTID)
      .collection("challenges")
      .doc(challenge.uid)
      .set(challenge);
  }
};

const createAttendees = () => {
  for (var challenge of getChallenges()) {
    for (var user of DEFAULT_USERS) {
      if (
        user.challenges[challenge.uid] &&
        user.challenges[challenge.uid].image
      ) {
        createPhoto(
          DEFAULT_EVENTID,
          challenge.uid,
          user.uid,
          user.challenges[challenge.uid].image,
          user.username,
          user.challenges[challenge.uid].likes
        );
        console.log("create photo ", challenge.uid, " - ", user.uid);
      }
    }
  }
};

const resetData = () => {
  for (var challenge of getChallenges()) {
    // reset challenge data
    console.log("challenge", challenge);
    db.collection("events")
      .doc(DEFAULT_EVENTID)
      .collection("challenges")
      .doc(challenge.uid)
      .set(
        {
          start: challenge.start,
          end: challenge.end,
          lastUpdated: challenge.lastUpdated
        },
        { merge: true }
      );

    // reset all photos
    db.collection("events")
      .doc(DEFAULT_EVENTID)
      .collection("challenges")
      .doc(challenge.uid)
      .collection("photos")
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          //doc.ref.delete();
          console.log(doc.id, "=>", doc.data());
          //doc.update({ lastUpdated: new Date(), created: new Date() });
          var setWithMerge = doc.ref.set(
            {
              lastUpdated: new Date(),
              created: new Date()
            },
            { merge: true }
          );
        });
      });
  }
};

const createPhoto = (
  eventId,
  challengeId,
  userId,
  imagePath,
  username,
  likes
) => {
  db.collection("events")
    .doc(eventId)
    .collection("challenges")
    .doc(challengeId)
    .collection("photos")
    .doc(userId)
    .set({
      uid: userId,
      path: imagePath,
      contentType: "image/jpeg",
      size: 1000,
      processed: false,
      username: username,
      likes: likes,
      lastUpdated: new Date(),
      created: new Date()
    })
    .then(function() {
      console.log("Document successfully written!");
    })
    .catch(function(error) {
      console.error("Error writing document: ", error);
    });
};

function Example(props) {
  const classes = useStyles();

  return (
    <div>
      <Typography
        component="h2"
        variant="h5"
        align="center"
        color="textPrimary"
      >
        Beispielprojekt
      </Typography>
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          createChallenges();
        }}
      >
        <Create className={classes.extendedIcon} />
        Veranstaltungen erstellen
      </Button>
      <br /> <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          createAttendees();
        }}
      >
        <People className={classes.extendedIcon} />
        Teilnehmer hinzufügen
      </Button>
      <br /> <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          resetData();
        }}
      >
        <Reset className={classes.extendedIcon} />
        Daten zurücksetzen
      </Button>
    </div>
  );
}
export default Example;
