import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import LogoutIcon from "@material-ui/icons/PowerSettingsNewTwoTone";
import LoginIcon from "@material-ui/icons/PersonTwoTone";
import logo from "../assets/img/vodo-logo-white.png";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  logo: {
    height: 40,
    marginRight: 20
  }
}));

function Header(props) {
  console.log("HEADER", props);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <a href="/">
            <img src={logo} alt="Logo" className={classes.logo} />
          </a>
          <Typography variant="h6" className={classes.title}>
            Backend
          </Typography>
          <div>
            {props.user ? (
              <Typography variant="h6" className={classes.title}>
                {props.user.displayName}{" "}
                <Button color="inherit" onClick={props.signOut}>
                  <LogoutIcon />
                </Button>
              </Typography>
            ) : (
              <Button color="inherit" onClick={props.signInWithGoogle}>
                <LoginIcon />
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
